import React, { useCallback, useEffect, useState } from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import './commonstyles.css';
import Pageone from "./PageOne/pageone";
import Pagetwo from "./PageTwo/pagetwo";
import Pagethree from "./PageThree/pagethree";
import Pagefour from "./PageFour/pagefour";
import { apicall_PatientData } from "./Savedata";
import { useAppSelector } from "../../app/hooks";


const PatientRegistration: React.FC = () => {

	/* Demographics data */
	const pdata: any = useAppSelector((state) => state.patient);    
	
	/* Insurance data */
	const pInsurence: any = useAppSelector((state) => state.insurance);
	// console.log("pInsurence : "+ JSON.stringify(pInsurence))

	const [formData, setFormData] = useState({
		patientId: pdata.patientId,
		first_name: pdata.first_name,
		last_name: pdata.last_name,
		full_name: pdata.full_name,
		demographics_sexAtBirth: pdata.demographics.sexAtBirth,
		demographics_genderIdentity: pdata.demographics.genderIdentity,
		demographics_preferredPronoun: pdata.demographics.preferredPronoun,
		demographics_religiousAffiliation: pdata.demographics.religiousAffiliation,
		demographics_maritalStatus: pdata.demographics.maritalStatus,
		demographics_race: pdata.demographics.race,
		demographics_ethnicity: pdata.demographics.ethnicity,
		//values
		demographics_sexAtBirthValue: pdata.demographics.sexAtBirthValue,
		demographics_genderIdentityValue: pdata.demographics.genderIdentityValue,
		demographics_preferredPronounValue: pdata.demographics.preferredPronounValue,
		demographics_religiousAffiliationValue: pdata.demographics.religiousAffiliationValue,
		demographics_maritalStatusValue: pdata.demographics.maritalStatusValue,
		demographics_raceValue: pdata.demographics.raceValue,
		demographics_ethnicityValue: pdata.demographics.ethnicityValue,
		email: pdata.email,
		consentToEmail: pdata.consentToEmail,
		consentToText: pdata.consentToText,
		homePhone: pdata.homePhone,
		workPhone: pdata.workPhone,
		workPhoneExtn: pdata.workPhoneExtn,
		cellPhone: pdata.cellPhone,
		preferredPhoneType: pdata.preferredPhoneType,
		preferredPhoneTypeCode: pdata.preferredPhoneTypeCode,
		address1: pdata.address.address1,
		address2: pdata.address.address2,
		city: pdata.address.city,
		state: pdata.address.state,
		zipcode: pdata.address.zipcode,
		dob: pdata.dob,
		isAdultPatient: pdata.isAdultPatient,  // true if patient has valid DOB and is over 18 years old
		cdoLanguageKey: pdata.cdoLanguageKey,   // this is the CDO specific language key from AEM for the current CDO.  This is not the ISO language code
		cdoLanguageName: pdata.cdoLanguageName,  // this is the CDO specific display name for the language in the current selected language
		photoid_front: pdata.photoid_front,
		photoid_back: pdata.photoid_back,
		emergencyContact_first_name: pdata.emergencyContact.first_name,
		emergencyContact_last_name: pdata.emergencyContact.last_name,
		emergencyContact_full_name: pdata.emergencyContact.full_name,
		emergencyContact_relationship: pdata.emergencyContact.relationship,
		emergencyContact_email: pdata.emergencyContact.email,
		emergencyContact_homePhone: pdata.emergencyContact.homePhone,
		emergencyContact_workPhone: pdata.emergencyContact.workPhone,
		emergencyContact_cellPhone: pdata.emergencyContact.cellPhone,
		representative_name: pdata.representative.name,
		representative_dob: pdata.representative.dob,
		representative_address1: pdata.representative.address.address1,
		representative_address2: pdata.representative.address.address2,
		representative_city: pdata.representative.address.city,
		representative_state: pdata.representative.address.state,
		representative_zipcode: pdata.representative.address.zipcode,
		representative_homePhone: pdata.representative.homePhone,
		representative_photoid_front: pdata.representative.photoid_front,
		representative_photoid_back: pdata.representative.photoid_back,
		advancedDirective_selection: pdata.advancedDirective.selection,
		accountBalance: pdata.accountBalance,

		coverage1_fhirInsuranceId: pInsurence.coverage1.fhirInsuranceId,
		coverage1_payorDisplay: pInsurence.coverage1.payorDisplay,
		coverage1_memberId: pInsurence.coverage1.memberId,
		coverage1_groupNumber: pInsurence.coverage1.groupNumber,
		coverage1_policyHolderName: pInsurence.coverage1.policyHolderName,
		coverage1_policyHolderDOB: pInsurence.coverage1.policyHolderDOB,
		coverage1_policyHolderRelationToPatientCode: pInsurence.coverage1.policyHolderRelationToPatientCode,
		coverage1_policyHolderRelationToPatientValue: pInsurence.coverage1.policyHolderRelationToPatientValue,
		coverage1_insuranceCardFrontImage: pInsurence.coverage1.insuranceCardFrontImage,
		coverage1_insuranceCardBackImage: pInsurence.coverage1.insuranceCardBackImage,
		coverage1_order: pInsurence.coverage1.order,

		coverage2_fhirInsuranceId: pInsurence.coverage2.fhirInsuranceId,
		coverage2_payorDisplay: pInsurence.coverage2.payorDisplay,
		coverage2_memberId: pInsurence.coverage2.memberId,
		coverage2_groupNumber: pInsurence.coverage2.groupNumber,
		coverage2_policyHolderName: pInsurence.coverage2.policyHolderName,
		coverage2_policyHolderDOB: pInsurence.coverage1.policyHolderDOB,
		coverage2_policyHolderRelationToPatientCode: pInsurence.coverage2.policyHolderRelationToPatientCode,
		coverage2_policyHolderRelationToPatientValue: pInsurence.coverage2.policyHolderRelationToPatientValue,
		coverage2_insuranceCardFrontImage: pInsurence.coverage2.insuranceCardFrontImage,
		coverage2_insuranceCardBackImage: pInsurence.coverage2.insuranceCardBackImage,


		hasSelfCoverage: pInsurence.hasSelfCoverage,
		hasCoverage1: pInsurence.hasCoverage1,
		hasCoverage2: pInsurence.hasCoverage2,
		insuranceProviderPayors: []

	});


	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData(prevState => ({ ...prevState, [name]: value }));
	};
	// console.log(formData);

	/*Save Modal construction start*/
	apicall_PatientData(formData);	
	/*Save Modal construction end*/

	return (
		<div className="mainFont">				
			<div id="pageOne">
	            <Pageone formData={formData} onChange={handleInputChange}/> 
	         </div>
	        <div id="pageTwo">
	            <Pagetwo formData={formData} onChange={handleInputChange}/> 
	        </div>
	        <div id="pageThree">
	           < Pagethree formData={formData} onChange={handleInputChange}/> 
	        </div>
	        <div id="pageFour">
	           <Pagefour formData={formData} onChange={handleInputChange} /> 
	        </div>


		</div>
	);
};
export default PatientRegistration;