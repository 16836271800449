import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import './pagethree.css';
import {InsuranceProviders} from  '../../../data/insuranceData';
import Aem, { AemContentTypes, AemKVPair } from '../../../lib/aem/components/Aem';

interface PageoneProps {
  formData: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Pagethree: React.FC<PageoneProps> = ({ formData, onChange }) => {

  const aemEmergencyRelationshipOptions: any[] =Aem.getList(
    "BODY_INSURANCE_INFO_LIST_2",
    InsuranceProviders
  );
  const handleInputChange = onChange;
  const [formInfo, setFormInfo] = useState({
    coverage1_payorDisplay:formData.coverage1_payorDisplay,
  });
  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormInfo({
      ...formInfo,
      [name]: value
    });
  };
  formData.coverage1_payorDisplay=formInfo.coverage1_payorDisplay;  
  
  return (
    <div className="form-page">
      <div className="form-container">
        <form className="responsive-form">
          <h4>Financial Responsibility</h4>
          <span><p>I understand that I am financially responsible for all the charges, whether or not paid by my insurance,unless specifically exempted by my insurance company’s contract with Optum.</p></span>
          <div className="form-row">
            <p>I,</p>
            <div className="form-group">

              <input
                id="policyHolderName"
                type="text"
                name="coverage1_policyHolderName"
                value={formData.coverage1_policyHolderName}
                onChange={handleInputChange}
              /><label htmlFor="policyHolderName" className="label">Name of patient</label>

            </div>
            <p>,hereby certify that I am eligible</p>
          </div>
          <div className="form-row">
            <p>for</p>
            <div className="form-group customselect">
              <select
                id="TXTMName"
                name="coverage1_payorDisplay"
                value={formData.coverage1_payorDisplay}
                onChange={handleSelectChange}
                className="form-select form-select-sm mt-3"
              >
                {InsuranceProviders.map((relationship, index) => (
                  <option key={index} value={relationship}>
                    {relationship}
                  </option>
                ))}
              </select><label htmlFor="payorDisplay" className="label">Insurance name</label>
            </div>
            <p>,benifits effective</p>
            <div className="form-group">

              <input
                id="effectiveDate"
                type="text"
                name="effectiveDate"
                value={formData.effectiveDate}
                onChange={handleInputChange}
              /><label htmlFor="effectiveDate" className="label">Effective Date</label>
            </div>
          </div>
          <p>I have chosen <strong>Optum</strong> to be my medical provider. I understand that if the above is not true, I am
            responsible for all charges related to services provided to me. Also, if the above is not true, I agree to pay in
            full for all services received within 30 days of receiving a bill from <strong>Optum</strong>.</p>
          <div className="form-row">

            <div className="form-group">

              <input
                id="patientfullName"
                type="text"
                name="patientfullName"
                value={formData.full_name}
                onChange={handleInputChange}
              /><label htmlFor="patientfullName" className="label">Signature of patient or responsible party</label>
            </div>
            <div className="form-group">

              <input
                id="Date1"
                type="text"
                name="Date1"
                value={formData.Date1}
                onChange={handleInputChange}
              /><label htmlFor="Date1" className="label">Date</label>
            </div>
          </div>
          <h4>Acknowledgment of receipt of Optum Notice of Privacy Practices</h4>
          <p>By signing this document, I acknowledge that I have been provided a copy of the Notice of Privacy Practices.
            This notice explains how my personal information can be used and disclosed by this medical office.</p>
          <div className="form-row">

            <div className="form-group">

              <input
                id="acknowledgment"
                type="text"
                name="coverage1_policyHolderName"
                value={formData.coverage1_policyHolderName}
                onChange={handleInputChange}
              /><label htmlFor="acknowledgment" className="label">Printed name </label>
            </div>
            <div className="form-group">

              <input
                id="Date2"
                type="text"
                name="Date2"
                value={formData.Date2}
                onChange={handleInputChange}
              /><label htmlFor="Date2" className="label">Date</label>
            </div>
          </div>
          <div className="form-group">
           <div className="input-container">
            <input
              id="signature"
              type="text"
              name="patientfullName"
              value={formData.full_name}
              onChange={handleInputChange}
              style={{ width: '50%' , paddingTop: '2px' }}
            /><label htmlFor="signature" className="label">Signature</label>
            </div>
          </div>
          <h4>Cellular telephone number communications</h4>
          <p>By providing my cellular telephone number to Optum physicians on this form, I agree to receive automated calls, prerecorded messages and/or text messages related to my health care from Optum, its affiliates and their respective physicians. I agree to receive calls including use of an auto dialer, artificial voice, prerecorded voice, and/or AI-generated technology to receive important information faster regarding appointment reminders, test results, prescription renewals, marketing, and outreach. I acknowledge that the Texting Terms of Use will be included in the first text message I receive. I acknowledge and agree that the text messages, which will be sent via unencrypted means, may contain Protected Health Information (PHI) and there is some risk of disclosure or interception of these messages. Message and data rates may apply. I may revoke or withdraw this consent at any time. Withdrawal of consent for text messages or automated calls can be made by replying STOP to the text messages or  calling<strong>1-800-403-4160.</strong></p>
          <div className="form-row">

            <div className="form-group">

              <input
                id="signature-cellular"
                type="text"
                name="patientfullName"
                value={formData.full_name}
                onChange={handleInputChange}
              /><label htmlFor="signature-cellular" className="label">Signature of patient or personal representative</label>
            </div>
            <div className="form-group">

              <input
                id="Date3"
                type="text"
                name="Date3"
                value={formData.Date3}
                onChange={handleInputChange}
              /><label htmlFor="Date3" className="label">Date</label>
            </div>
          </div>
          <div className="form-row">

            <div className="form-group">

              <input
                id="representative_fullname"
                type="text"
                name="representative_fullname"
                value={formData.representative_fullname}
                onChange={handleInputChange}
              /><label htmlFor="representative_fullname" className="label">Personal representative’s name</label>
            </div>
            <div className="form-group">

              <input
                id="representative_relation"
                type="text"
                name="representative_relation"
                value={formData.representative_relation}
                onChange={handleInputChange}
              /><label htmlFor="representative_relation" className="label">Relationship to patient/minor</label>
            </div>
          </div>
          <h4>Open Payments notice to patients</h4>
          <p>The Open Payments database is a federal tool used to search payments made by drug and device
            companies to physicians and teaching hospitals. It can be found at <strong>openpaymentsdata.cms.gov</strong></p>
          <div className="form-row">

            <div className="form-group">

              <input
                id="signature-open"
                type="text"
                name="patientfullName"
                value={formData.full_name}
                onChange={handleInputChange}
              /><label htmlFor="signature-open" className="label">Patient or representative signature</label>
            </div>
            <div className="form-group">

              <input
                id="Date4"
                type="text"
                name="Date4"
                value={formData.Date4}
                onChange={handleInputChange}
              /><label htmlFor="Date4" className="label">Date</label>
            </div>
          </div>
          <div className="page-number">
            3
          </div>
        </form>
      </div>
      <div className='space'></div>
    </div>
  );
};


export default Pagethree;