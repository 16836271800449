import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import './pagefour.css';


interface PageoneProps { 
  formData: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Pagefour: React.FC<PageoneProps> = ({ formData, onChange }) => {
  const statements = [
    "I have provided a copy of my Advance Health Care Directive Form to Optum to be placed in my chart.",
    "I will provide a copy of my Advance Health Care Directive to Optum.",
    "I do not have an Advance Health Care Directive at this time. I understand that it is my responsibility to discuss this matter with my primary care provider."
  ];
  // let patientfullName = JSON.parse(JSON.stringify(localStorage.getItem("patientfullName")));
  // let policyHolderName = JSON.parse(JSON.stringify(localStorage.getItem("policyHolderName")));
return (
	<div className="form-page">
      <div className="form-container">
        <form className="responsive-form">
          <h4>Advance health care directive acknowledgment</h4>
          <h4>Optum, in compliance with the Patient Self Determination Act of 1990, ensures a patient’s right to 
self-determination by inviting patients to participate in decisions about their health care. This is 
accomplished through the planning and communication of their medical treatment wishes through an 
Advance Health Care Directive Acknowledgment Form.</h4>
        <h4>My initials next to one of the following statements indicates my current Advance Directive status.</h4>
        
        <table>
        <thead>
          <tr>
            <th className="initials">Initials</th>
            <th className="statement">Statement</th>
          </tr>
        </thead>
        <tbody>
          {statements.map((statement, index) => (
            <tr key={index}>
              <td className="initials">
    
              </td>
              <td className="statement">
                {statement}
                {index === 0 && (
                  <label>
                    <input type="checkbox" name={`checkbox-row${index + 1}`} />
                    <span>Scanned to EHR</span>
                  </label>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h4>My signature acknowledges that I have informed Optum of my right to participate in making decisions 
about my medical treatment by executing an Advance Health Care Directive.</h4>
          <div className="form-row">
  
            <div className="form-group">
              
              <input
                id="TXTSign4"
                type="text"
                name="full_name"
                value={formData.full_name}
                
              /><label htmlFor="TXTSign4" className="label">Patient signature</label>
    
            </div>
            <div className="form-group">
              
              <input
                id="TXTPrintName"
                type="text"
                name="coverage1_policyHolderName"
                value={formData.coverage1_policyHolderName}
                
              /><label htmlFor="TXTPrintName" className="label">Printed name</label>
    
            </div>
            <div className="form-group">
              
              <input
                id="Date5"
                type="text"
                name="Date5"
                value={formData.Date5}
              /><label htmlFor="Date5" className="label">Date</label>
            </div>
            </div>
          <div className="form-row">
          <div className="form-group">
              
              <input
                id="witness_signature"
                type="text"
                name="witness_signature"
                value={formData.witness_signature}
                
              /><label htmlFor="witness_signature" className="label">Witness signature</label>
    
            </div>
            <div className="form-group">
              
              <input
                id="witness_name"
                type="text"
                name="witness_name"
                value={formData.witness_name}
                
              /><label htmlFor="witness_name" className="label">Printed name</label>
    
            </div>
            <div className="form-group">
              
              <input
                id="Date6"
                type="text"
                name="Date6"
                value={formData.Date6}
                
              /><label htmlFor="Date6" className="label">Date</label>
    
            </div>
            
          </div>
          <h4>For office use only:</h4>
          <div >
              <input type="checkbox" />
              <span>Written and verbal information was provided to the patient. (Advance health care directive packet)</span>
          </div>
          <div className="form-row">
          <p>Comments:</p>
            <div className="form-group">
              
              <input
                id="comments"
                type="text"
                value={formData.comments}
              />
            </div>
            </div>
            <img className="logo" src = {`${process.env.PUBLIC_URL}/Optum_logo.png`}/>
            <div className="copyright">
            <p>If you are a guardian or court-appointed representative, you must provide a copy of your legal authorization to represent the patient.</p>
            <p>The company does not discriminate on the basis of race, color, national origin, sex, age, or disability in health programs and activities. 
We provide free services to help you communicate with us. This includes letters in other languages or large print. Or, you can ask for an 
interpreter. To ask for help, please call 1-800-403-4160, TTY 711. ATENCIÓN: Si habla español (Spanish), hay servicios de asistencia de 
idiomas, sin cargo, a su disposición. Llame al 1-800-403-4160, TTY 711. 請注意 ：如果您說中文 (Chinese) 
， 我們免費為您提供 語言協助服務 。請致電 ：1-800-403-4160, TTY 711 。</p>
        <p>Optum is a registered trademark of Optum, Inc. in the U.S. and other jurisdictions. All other trademarks are the property of 
their respective owners. Because we are continuously improving our products and services, Optum reserves the right to change 
specifications without prior notice. Optum is an equal opportunity employer.</p>
       <p>© 2024 Optum, Inc. All rights reserved. 23684 01/24</p>
       </div>
       <div className="page-number">
            4
        </div>
        </form>
      </div>
      <div className='space'></div>
    </div>
  );
};
export default Pagefour;