import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import './pageone.css'


import Aem, { AemContentTypes, AemKVPair } from '../../../lib/aem/components/Aem';
import { EthnicityList, GenderIdentityList, MaritalStatusList, PreferredPronounsList, RaceList, ReligiousAffiliationKVPairs, SexAssignedBirthList } from "../../../data/demographicsData";
import { width } from "@mui/system";
import { LanguageIsoKvPairs } from '../../../data/languageData';
import { EmergencyRelationshipsList } from '../../../data/relationshipsData';

interface PageoneProps { 
  formData: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Pageone: React.FC<PageoneProps> = ({ formData, onChange }) => {
  
    
  const aemLanguageOptions: any[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_NAME_PRI_LANG_CODES",
    "BODY_DEMOGRAPHICS_PATIENT_NAME_PRI_LANG_LIST",
    LanguageIsoKvPairs,
  );
  const sexAssignedBirthItems: AemKVPair[] = Aem.getListKVPairs(
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_1",
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_1",
		Aem.toKvPairs(SexAssignedBirthList)
	  );
    const aemEmergencyRelationshipOptions: any[] =Aem.getList(
      "BODY_DEMOGRAPHICS_PATIENT_CONTACT_LIST_1",
      EmergencyRelationshipsList
    );
    const genderItems: AemKVPair[] = Aem.getListKVPairs(
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_2",
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_2",
      Aem.toKvPairs(GenderIdentityList)
      );
	  const preferredPronounsItems: AemKVPair[] = Aem.getListKVPairs(
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_3",
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_3",
		Aem.toKvPairs(PreferredPronounsList)
	  );
	  const maritalStatusItems: AemKVPair[] = Aem.getListKVPairs(
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_4",
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_4",
		Aem.toKvPairs(MaritalStatusList)
	  );
	  const raceItems: AemKVPair[] = Aem.getListKVPairs(
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_5",
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_5",
		Aem.toKvPairs(RaceList)
	  );
	  const ethnicityItems: AemKVPair[] = Aem.getListKVPairs(
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_6",
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_6",
		Aem.toKvPairs(EthnicityList)
	  );
	  const religiousItems: AemKVPair[] = Aem.getListKVPairs(
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_7",
		"BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_7",
		ReligiousAffiliationKVPairs
	  );

    const prefPhoneTypeOptions: AemKVPair[] = Aem.getListKVPairs(
      "BODY_DEMOGRAPHICS_PATIENT_CONTACT_PREF_PHONE_TYPE_CODES",
      "BODY_DEMOGRAPHICS_PATIENT_CONTACT_PREF_PHONE_TYPE_LIST",
      []
    );
    console.log(prefPhoneTypeOptions);
    const preferredPhoneTypes = [
      {Key: 'cellPhone', Value: 'cellPhone'},
      {Key: 'homePhone', Value: 'homePhone'},
      {Key: 'workPhone', Value: 'workPhone'},
      {Key: 'workPhoneExtn', Value: 'workPhoneExtn'}
    ]
  const handleInputChange = onChange;  
  
  const [formState, setFormState] = useState({
    demographics_genderIdentity: formData.demographics_genderIdentity,
    demographics_sexAtBirth:formData.demographics_sexAtBirth,
    demographics_maritalStatus:formData.demographics_maritalStatus,
    demographics_ethnicity:formData.demographics_ethnicity,
    demographics_race:formData.demographics_race,
    cdoLanguageKey:formData.cdoLanguageKey,
    preferredPhoneTypeCode:formData.preferredPhoneTypeCode
  });
  const [formInfo, setFormInfo] = useState({
    cdoLanguageKey: formData.cdoLanguageKey,
    cdoLanguageName: formData.cdoLanguageName,
    emergencyContact_relationship:formData.emergencyContact_relationship,
    demographics_preferredPronoun:formData.demographics_preferredPronoun,
		demographics_preferredPronounValue:formData.demographics_preferredPronounValue,
  });
  const handleChange = (event) => {
		const { name, value } = event.target;
        let updatedFormInfo = { ...formInfo };
        if (name === 'preferredPronoun') {
			const selectedPronoun = preferredPronounsItems.find(item => item.Key === value);
			updatedFormInfo = {
				...updatedFormInfo,
				demographics_preferredPronoun: value,
				demographics_preferredPronounValue:selectedPronoun? selectedPronoun.Value : ''
			};
		} else if (name === 'cdoLanguage') {
			const selectedLanguage = aemLanguageOptions.find(item => item.Key === value);
			updatedFormInfo = {
				...updatedFormInfo,
				cdoLanguageKey: value,
				cdoLanguageName: selectedLanguage ? selectedLanguage.Value : ''
			};
		}
		setFormInfo(updatedFormInfo);
		formData.cdoLanguageKey = updatedFormInfo.cdoLanguageKey;
    	formData.cdoLanguageName = updatedFormInfo.cdoLanguageName;
    	formData.demographics_preferredPronoun = updatedFormInfo.demographics_preferredPronoun;
		formData.demographics_preferredPronounValue=updatedFormInfo.demographics_preferredPronounValue
    };
  const updation = (field, key, value) =>{
    formData[field] = key
    formData[field + "Value"] = value
    formData[field + "Code"] = value[0].toUpperCase();
  }
  const handleCheckChange = (event) => {
    const { name, value} = event.target;
    const arrValues = value.split(',');
    setFormState(() => ({
      ...formState,
      [name]: arrValues[0],
    }));
    updation(name, arrValues[0], arrValues[1]);
  };
  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormInfo({
      ...formInfo,
      [name]: value
    });
  };
  formData.emergencyContact_relationship=formInfo.emergencyContact_relationship;
 
  console.log('FormData :'+ JSON.stringify(formData));
  return (
    <div className='form-page'>
      <div className="form-container">
        <form className="responsive-form">
          <div className="header">
            <div className="left-corner">Patient Registration</div>
            <img className="right-corner" src = {`${process.env.PUBLIC_URL}/Optum_logo.png`}/>
          </div>
          <div className="font-size">
            <h4><p>Patient information<span className="font-size1">(please print)</span></p></h4>
            <div className="form-row">
              <p className='marginTop'>Last name</p>
              <div className="form-group">
                <input
                  id="TXTLName"
                  type="text"
                  name="last_name" value={formData.last_name} onChange={handleInputChange}
                />
              </div>
              <p className='marginTop'>First name</p>
              <div className="form-group">
                <input
                  id="TXTFName"
                  type="text"
                  name="first_name" value={formData.first_name} onChange={handleInputChange}
                />
              </div>
              <p className='marginTop'>Middle name</p>
              <div className="form-group">
                <input
                  id="TXTMName"
                  type="text"
                  name="middle_name"
                  value={formData.middle_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-row">
              <p className='marginTop'>Preferred name</p>
              <div className="form-group customselect">
              <select
                    className="form-select form-select-sm mt-3"
                    value={formData.demographics_preferredPronoun}
                    name="preferredPronoun"
                    onChange={handleChange}
                  >
                    {
                      preferredPronounsItems.map(item => (
                        <option
                          key={item.Key}
                          value={item.Key}
                        >
                          {item.Value}
                        </option>
                      ))
                    }
                  </select>
              </div>
            </div>
            <div className="form-row">
              <p className='marginTop'>Other name(s) you are also known as</p>
              <div className="form-group">
                <input
                  id="TXTOName"
                  type="text"
                  name="other_name" value={formData.other_name} onChange={handleInputChange}
                />
              </div>
              <p className='marginTop'>DOB</p>
              <div className="form-group">
                <input
                  id="TXTDOB"
                  type="text"
                  name="dob"
                  max="99999"
                  pattern="[0-9]{2}"
                  value={formData.dob} onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="container">
      <p>Gender identity</p>
      <div className="checkboxes">
      <label>
        <input
          type="checkbox"
          checked={genderItems[3].Value === formState.demographics_genderIdentity}
          name="demographics_genderIdentity"
          value={[genderItems[3]["Key"], genderItems[3]["Value"]]} 
          onChange={handleCheckChange}
        />
        Man
      </label>
      <label>
        <input
          type="checkbox"
          checked={genderItems[0].Value === formState.demographics_genderIdentity}
          name="demographics_genderIdentity"
          value={[genderItems[0]["Key"], genderItems[0]["Value"]]} 
          onChange={handleCheckChange}
        />
        Woman
      </label>
        <label>
          <input
            type="checkbox"
            checked={genderItems[2].Value === formState.demographics_genderIdentity} 
            name="demographics_genderIdentity" 
            value={[genderItems[2]["Key"], genderItems[2]["Value"]]} 
            onChange={handleCheckChange}
          />
          Nonbinary
        </label>
        <label>
          <input
            type="checkbox"
            checked={genderItems[2].Value === formState.demographics_genderIdentity} 
            name="demographics_genderIdentity" value={[genderItems[2]["Key"], genderItems[2]["Value"]]} 
            onChange={handleCheckChange}
          />
          Genderqueer 
        </label>
        <label>
          <input
            type="checkbox"
            checked={genderItems[5].Value === formState.demographics_genderIdentity} name="demographics_genderIdentity" value={[genderItems[5]["Key"], genderItems[5]["Value"]]}  onChange={handleCheckChange}
          />
          Prefer not to disclose
        </label>
        <label>
                <input
                  id="TXTOName"
                  type="checkbox"
                  checked={genderItems[6].Value === formState.demographics_genderIdentity} name="demographics_genderIdentity" value={[genderItems[6]["Key"], genderItems[6]["Value"]]}  onChange={handleCheckChange}
                />
                other</label>
                <label>
                <input 
                  id="TXTOtherGI" type="text" name="demographics_genderIdentity" value={formData.other_GI} style={{width:'95px'}}
                />
                </label>
      </div>
    </div>
              <div className="container">
      <p>Sex assigned at birth</p>
      <div className="checkboxes">
      <label>
          <input
            id={sexAssignedBirthItems[0]["Key"]}
            type="checkbox"
            checked={sexAssignedBirthItems[0]["Key"] === formState.demographics_sexAtBirth} name="demographics_sexAtBirth" value={[sexAssignedBirthItems[0]["Key"], sexAssignedBirthItems[0]["Value"]]}  onChange={handleCheckChange}
          />
          Man
        </label>
        <label>
          <input
            type="checkbox"
            checked={sexAssignedBirthItems[1]["Key"] === formState.demographics_sexAtBirth} name="demographics_sexAtBirth" value={[sexAssignedBirthItems[1]["Key"], sexAssignedBirthItems[1]["Value"]]} onChange={handleCheckChange}
          />
          Woman
        </label>
        <label>
          <input
            type="checkbox"
            checked={sexAssignedBirthItems[3]["Key"] === formState.demographics_sexAtBirth} name="demographics_sexAtBirth" value={[sexAssignedBirthItems[3]["Key"], sexAssignedBirthItems[3]["Value"]]} onChange={handleCheckChange}
          />
          Intersex
        </label>
        <label>
          <input
            type="checkbox"
            checked={sexAssignedBirthItems[3]["Key"] === formState.demographics_sexAtBirth} name="demographics_sexAtBirth" value={[sexAssignedBirthItems[3]["Key"], sexAssignedBirthItems[3]["Value"]]} onChange={handleCheckChange}
          />
          Genderqueer 
        </label>
        <label>
          <input
            type="checkbox"
            checked={sexAssignedBirthItems[2]["Key"] === formState.demographics_sexAtBirth} name="demographics_sexAtBirth" value={[sexAssignedBirthItems[2]["Key"], sexAssignedBirthItems[2]["Value"]]} onChange={handleCheckChange}
          />
          Prefer not to disclose
        </label>
        <label>
                <input
                  id="TXTOName"
                  type="checkbox"
                  checked={sexAssignedBirthItems[3]["Key"] === formState.demographics_sexAtBirth} name="demographics_sexAtBirth" value={[sexAssignedBirthItems[3]["Key"], sexAssignedBirthItems[3]["Value"]]} onChange={handleCheckChange}
                />
                other</label>
                <label>
                <input
                  id="TXTSAbirth"
                  name="demographics_sexAtBirth"
                  value={formData.other_SAbirth}
                  onChange={handleInputChange}
                  style={{width:'82px'}}
                />
                </label>
      </div>
    </div>
          <div className="container">
      <p>Relationship status</p>
      <div className="checkboxes">
      <label>
          <input
            type="checkbox"
            checked={maritalStatusItems[0]["Key"] === formData.demographics_maritalStatus} name="demographics_maritalStatus" value={[maritalStatusItems[0]["Key"], maritalStatusItems[0]["Value"]]} onChange={handleCheckChange}
          />
          Single
        </label>
        <label>
          <input
            type="checkbox"
            checked={maritalStatusItems[1]["Key"] === formData.demographics_maritalStatus} name="demographics_maritalStatus" value={[maritalStatusItems[1]["Key"], maritalStatusItems[1]["Value"]]} onChange={handleCheckChange}
          />
          In a relationship
        </label>
        <label>
          <input
            type="checkbox"
            checked={maritalStatusItems[1]["Key"] === formData.demographics_maritalStatus} name="demographics_maritalStatus" value={[maritalStatusItems[1]["Key"], maritalStatusItems[1]["Value"]]} onChange={handleCheckChange}
          />
          Married
        </label>
        <label>
          <input
            type="checkbox"
            checked={maritalStatusItems[2]["Key"] === formData.demographics_maritalStatus} name="demographics_maritalStatus" value={[maritalStatusItems[2]["Key"], maritalStatusItems[2]["Value"]]} onChange={handleCheckChange}
          />
          Widowed 
        </label>
        <label>
          <input
            type="checkbox"
            checked={maritalStatusItems[3]["Key"] === formData.demographics_maritalStatus} name="demographics_maritalStatus" value={[maritalStatusItems[3]["Key"], maritalStatusItems[3]["Value"]]} onChange={handleCheckChange}
          />
          Separated
        </label>
        <label>
          <input
            type="checkbox"
            checked={maritalStatusItems[4]["Key"] === formData.demographics_maritalStatus} name="demographics_maritalStatus" value={[maritalStatusItems[4]["Key"], maritalStatusItems[4]["Value"]]} onChange={handleCheckChange}
          />
          Divorced
        </label>
      </div>
    </div>
            <div className="border-form">
              <p >Required information</p>
              <div className="form-row">
                <p className='marginTop'>Address</p>
                <div className="form-group">
                  <input
                    id="TXTAddress"
                    type="text"
                    name="address1" value={formData.address1} onChange={handleInputChange}
                  />
                </div>
                <p className='marginTop'>Apt. </p>
                <div className="form-group">
                  <input
                    id="TXTApt"
                    type="text"
                    name="address2" value={formData.address2} onChange={handleInputChange} 
                  />
                </div>
              </div>
              <div className="form-row">
                <p className='marginTop'>City</p>
                <div className="form-group">
                  <input
                    id="TXTCity"
                    type="text"
                    name="city" value={formData.city} onChange={handleInputChange} 
                  />
                </div>
                <p className='marginTop'>State</p>
                <div className="form-group">
                  <input
                    id="TXTState"
                    type="text"
                    name="state" value={formData.state} onChange={handleInputChange}
                  />
                </div>
                <p className='marginTop'>ZIP</p>
                <div className="form-group">
                  <input
                    id="TXTZip"
                    type="text"
                    name="zipcode" value={formData.zipcode} onChange={handleInputChange}
                  />
                </div>
              </div>
              <p>Phone numbers(please check box of your preferred contact number) </p>
             <div className="container">
      <div className="checkboxes">
      <label>
        <input
          id="TXTOName"
          type="checkbox"
          checked={preferredPhoneTypes[1]["Value"] === formData.preferredPhoneType} name="preferredPhoneType" value={[preferredPhoneTypes[1]["Key"], preferredPhoneTypes[1]["Value"]]} onChange={handleCheckChange} 
        />
        Home
        
        <input
          id="TXTHome" type="text"
          style={{width:'230px', lineHeight:'2.0',fontSize: '0.95em'}}
          name="homePhone" value={formData.homePhone} onChange={handleInputChange}
        />
        </label>
        <label >
        <input
          id="TXTHome"
          type="checkbox"
          checked={preferredPhoneTypes[0]["Value"] === formData.preferredPhoneType} name="preferredPhoneType" value={[preferredPhoneTypes[0]["Key"], preferredPhoneTypes[0]["Value"]]} onChange={handleCheckChange} 
        />
        Cell
        
        <input
          id="TXTCell"
          type="text"
          style={{width:'230px', lineHeight:'2.0',fontSize: '0.95em'}}
          name="cellPhone"
          value={formData.cellPhone}
          onChange={handleInputChange}
        />
        </label>
      </div>
    </div>
    <div className="container">
      <div className="checkboxes">
      <label >
        <input
          id="TXTOName"
          type="checkbox"
          checked={preferredPhoneTypes[2]["Value"] === formData.preferredPhoneType} name="preferredPhoneType" value={[preferredPhoneTypes[2]["Key"], preferredPhoneTypes[2]["Value"]]} onChange={handleCheckChange}
        />
        Work</label>
        <label>
        <input
          id="TXTWork" type="text"
          name="workPhone"
          style={{width:'230px', lineHeight:'2.0',fontSize: '0.95em'}}
          value={formData.workPhone}
          onChange={handleInputChange}
        />
        </label>
        <label >
          <input
            id="TXTHome"
            type="checkbox"
            checked={preferredPhoneTypes[3]["Value"] === formData.preferredPhoneType} name="preferredPhoneType" value={[preferredPhoneTypes[3]["Key"], preferredPhoneTypes[3]["Value"]]} onChange={handleCheckChange}
          />
          Work ext.</label>
        <label>
        <input
          id="TXTWorkExt" type="text"
          name="workPhoneExtn"
          style={{width:'230px', lineHeight:'2.0',fontSize: '0.95em'}}
          value={formData.workPhoneExtn}
          onChange={handleInputChange}
        />
        </label>
      </div>
    </div>
              <div className="form-row">
                <p className='marginTop'>Email </p>
                <div className="form-group">
                  <input
                    id="TXTEmail"
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    // style={{ paddingTop: '2px' }}
                  />
                </div>
              </div>
              <div className="font-lighter"><p>By providing your email above, you acknowledge the emails may contain your Protected Health Information and will be sent unencrypted. There is a risk of interception or disclosure of the contents of these emails. </p></div>
            </div>
            <p>Emergency contact </p>
            <div className="form-row">
              <p className='marginTop'>Last name</p>
              <div className="form-group">
                <input
                  id="TXTELName"
                  type="text"
                  name="emergencyContact_last_name"
                  value={formData.emergencyContact_last_name}
                  onChange={handleInputChange}
                />
              </div>
              <p className='marginTop'>First</p>
              <div className="form-group">
                <input
                  id="TXTEFName"
                  type="text"
                  name="emergencyContact_first_name"
                  value={formData.emergencyContact_first_name}
                  onChange={handleInputChange}
                />
              </div>
              <p className='marginTop'>Relationship</p>
              <div className="form-group customselect">
                <select
                  id="TXTMName"
                  name="emergencyContact_relationship"
                  value={formData.emergencyContact_relationship}
                  onChange={handleSelectChange}
                  className="form-select form-select-sm mt-3"
                >
                  {EmergencyRelationshipsList.map((relationship, index) => (
                    <option key={index} value={relationship}>
                      {relationship}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <p className='marginTop'>Address</p>
              <div className="form-group">
                <input
                  id="TXTEAddress"
                  type="text"
                  name="emergencyContact_address"
                  value={formData.emergencyContact_address}
                  onChange={handleInputChange}
                />
              </div>
              <p className='marginTop'>Apt.</p>
              <div className="form-group">
                <input
                  id="TXTEApt"
                  type="text"
                  name="emergencyContact_appt"
                  value={formData.emergencyContact_appt}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-row">
              <p className='marginTop'>City</p>
              <div className="form-group">
                <input
                  id="TXTECity"
                  type="text"
                  name="emergencyContact_city"
                  value={formData.emergencyContact_city}
                  onChange={handleInputChange}
                />
              </div>
              <p className='marginTop'>State</p>
              <div className="form-group">
                <input
                  id="TXTEState"
                  type="text"
                  name="emergencyContact_state"
                  value={formData.emergencyContact_state}
                  onChange={handleInputChange}
                />
              </div>
              <p className='marginTop'>ZIP</p>
              <div className="form-group">
                <input
                  id="TXTEZip"
                  type="text"
                  name="emergencyContact_zip"
                  value={formData.emergencyContact_zip}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-row">
              <p className='marginTop'>Home phone</p>
              <div className="form-group">
                <input
                  id="TXTEHome"
                  type="text"
                  name="emergencyContact_homePhone"
                  value={formData.emergencyContact_homePhone}
                  onChange={handleInputChange}
                />
              </div>
              <p className='marginTop'>Cell</p>
              <div className="form-group">
                <input
                  id="TXTEHome"
                  type="text"
                  name="emergencyContact_cellPhone"
                  value={formData.emergencyContact_cellPhone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-row">
              <p className='marginTop'>Work</p>
              <div className="form-group">
                <input
                  id="TXTEWork"
                  type="text"
                  name="emergencyContact_workPhone"
                  value={formData.emergencyContact_workPhone}
                  onChange={handleInputChange}
                />
              </div>
              <p className='marginTop'>Work ext.</p>
              <div className="form-group">
                <input
                  id="TXTEWorkExt"
                  type="text"
                  name="emergencyContact_workPhoneext"
                  value={formData.emergencyContact_workPhoneext}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="border-form">
              <p>Required information </p>
              <div className="container">
      <p>Ethnicity(select one)</p>
      <div className="checkboxes">
      <label>
          <input
            type="checkbox"
            checked={ethnicityItems[0]["Key"] === formData.demographics_ethnicity} name="demographics_ethnicity" value={[ethnicityItems[0]["Key"], ethnicityItems[0]["Value"]]} onChange={handleCheckChange}
          />
          Hispanic or Latino or Spanish origin
        </label>
        <label>
          <input
            type="checkbox"
            checked={ethnicityItems[1]["Key"] === formData.demographics_ethnicity} name="demographics_ethnicity" value={[ethnicityItems[1]["Key"], ethnicityItems[1]["Value"]]} onChange={handleCheckChange}
          />
          Not Hispanic, Latino or Spanish origin
        </label>
        <label>
          <input
            type="checkbox"
            checked={ethnicityItems[2]["Key"] === formData.demographics_ethnicity} name="demographics_ethnicity" value={[ethnicityItems[2]["Key"], ethnicityItems[2]["Value"]]} onChange={handleCheckChange}
          />
          Prefer not to disclose
        </label>
      </div>
    </div>
    <div className="container">
      <p>Race(select one) </p>
      <div className="checkboxes">
      <label>
          <input
            type="checkbox"
            checked={raceItems[0]["Key"] === formData.demographics_race} name="demographics_race" value={[raceItems[0]["Key"], raceItems[0]["Value"]]} onChange={handleCheckChange}
          />
          American Indian/Alaska native
        </label>
        <label>
          <input
            type="checkbox"
            checked={raceItems[2]["Key"] === formData.demographics_race} name="demographics_race" value={[raceItems[2]["Key"], raceItems[2]["Value"]]} onChange={handleCheckChange}
          />
          Black or African American
        </label>
        <label>
          <input
            type="checkbox"
            checked={raceItems[3]["Key"] === formData.demographics_race} name="demographics_race" value={[raceItems[3]["Key"], raceItems[3]["Value"]]} onChange={handleCheckChange}
          />
          Native Hawaiian or Pacific Islander
        </label>
        <label>
          <input
            type="checkbox"
            checked={raceItems[1]["Key"] === formData.demographics_race} name="demographics_race" value={[raceItems[1]["Key"], raceItems[1]["Value"]]} onChange={handleCheckChange}
          />
          Asian
        </label>
        <label>
          <input
            type="checkbox"
            checked={raceItems[4]["Key"] === formData.demographics_race} name="demographics_race" value={[raceItems[4]["Key"], raceItems[4]["Value"]]} onChange={handleCheckChange}
          />
          White or Caucasian
        </label>
      </div>
    </div>
              <div className="form-row">
                <p className='marginTop'>Preferred language</p>
                <div className="form-group customselect">
                  <select
                    className="form-select form-select-sm mt-3"
                    value={formData.cdoLanguageKey}
                    name="cdoLanguage"
                    onChange={handleChange}
                  >
                    {
                      aemLanguageOptions.map(item => (
                        <option
                          key={item.Key}
                          value={item.Key}
                        >
                          {item.Value}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: '25px' }}></div>
          <div className="page-number">
            1
          </div>
        </form>
      </div>
      <div className='space'></div  >
    </div>
  );
};
export default Pageone;