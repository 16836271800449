import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import './pagetwo.css'
import { ReactComponent as Svg2 } from '../PageTwo/2/2.svg';
import Aem, { AemContentTypes, AemKVPair } from '../../../lib/aem/components/Aem';
import { RelationshipsList } from '../../../data/relationshipsData';

interface PageoneProps { 
  formData: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Pagetwo: React.FC<PageoneProps> = ({ formData, onChange }) => {
  const aemRelationshipOptions: any[] =Aem.getList("BODY_SELFIDENTIFY_TEXT_LIST_1", RelationshipsList);
	let pdata = formData;
  const handleInputChange = onChange; 
  const [selected, setSelected] = useState(null);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelected(value);
  };
return (
	<div className="form-page">
      <div className="form-container">
        <form className="responsive-form">
        <div className="form-row">
            <h4>Occupation</h4>
            <div className="form-group">
              
              <input
                id="TXToccupation"
                type="text"
                name="occupation"
                onChange={handleInputChange}
              />
    
            </div>
         </div>
       <div className="form-row1">
        <h4 className="form-row-title">Have you ever been a patient in any Optum facility before?</h4>
        <div className="form-group1">
        <input
        id="TXToptumFacilityY"
        type="checkbox"
        name="optumFacility"
        value="Y"
        checked={selected === 'Y'}
        onChange={handleChange}
      />
        </div>
        <p className="form-row-option"><strong>Yes</strong></p>
        <div className="form-group1">
        <input
        id="TXToptumFacilityN"
        type="checkbox"
        name="optumFacility"
        value="N"
        checked={selected === 'N'}
        onChange={handleChange}
      />
        </div>
        <p className="form-row-option"><strong>No</strong></p>
      </div>
       <div className="form-row">
            <h4 className='marginTop'>If yes, state the location/provider</h4>
            <div className="form-group">
              
              <input
                 id="TXTrname"
                 type="text"
                 name="representative_name"
                 value={formData.representative_name}
                 onChange={handleInputChange}
              />
    
            </div>
        </div>
        <p className='marginTop'><strong>Responsible party information</strong>(do not complete if patient is responsible party)</p>
        <div className="form-row">
          <h4 className='marginTop'>Relationship to patient</h4>
          <div className="form-group customselect">
            <select
              id="TXTMName"
              name="representative_relationship"
              value={formData.representative_relationship}
              className="form-select form-select-sm mt-3"
            >
              {RelationshipsList.map((relationship, index) => (
                <option key={index} value={relationship}>
                  {relationship}
                </option>
              ))}
            </select>
          </div>
        </div>
          <div className="form-row">
            <h4 className='marginTop'>Last name </h4>
            <div className="form-group">
              
              <input
                id="TXTrlast_name"
                type="text"
                name="representative_lastname"
                value={formData.representative_lastname}
                onChange={handleInputChange}
              />
    
            </div>
            <h4 className='marginTop'>First </h4>
            <div className="form-group">
              
              <input
                id="TXTrfirst_name"
                type="text"
                name="representative_firstname"
                value={formData.representative_firstname}
                onChange={handleInputChange}
              />
    
            </div>
            <h4 className='marginTop'>Middle</h4>
            <div className="form-group">
              
              <input
                id="TXTrmiddle_name"
                type="text"
                name="representative_middlename"
                value={formData.representative_middlename}
                onChange={handleInputChange}
              />
    
            </div>
          </div>
          <div className="form-row">
            <h4 className='marginTop'>Driver’s license number</h4>
            <div className="form-group">
              
              <input
                id="TXTDrivelIcn"
                type="text"
                name="representative_driver_license"
                value={formData.representative_driver_license}
                onChange={handleInputChange}
              />
    
            </div>
            <h4 className='marginTop'>DOB</h4>
            <div className="form-group">
              
              <input
                 id="TXTDOB"
                 type="text"
                 name="representative_dob"
                 max="99999"
                 pattern="[0-9]{2}"
                 value={formData.representative_dob}
                 onChange={handleInputChange}
              />
    
            </div>
          </div>
          <div className="form-row">
            <h4 className='marginTop'>Address</h4>
            <div className="form-group">
              
              <input
                 id="TXTAddress1"
                 type="text"
                 name="representative_address1"
                 value={formData.representative_address}
                 onChange={handleInputChange}
              />
    
            </div>
            <h4 className='marginTop'>Apt.</h4>
            <div className="form-group">              
              <input
                   id="TXTApt"
                   type="text"
                   name="representative_appt"
                   value={formData.representative_appt}
                   onChange={handleInputChange}
              />
    
            </div>
          </div>
          <div className="form-row">
            <h4 className='marginTop'>City</h4>
            <div className="form-group">
              <input
                id="TXTcity"
                type="text"
                name="representative_city"
                value={formData.representative_city}
                onChange={handleInputChange}
              />
    
            </div>
            <h4 className='marginTop'>State</h4>
            <div className="form-group">
              <input
                id="TXTstate"
                type="text"
                name="representative_state"
                value={formData.representative_state}
                onChange={handleInputChange}
              />
    
            </div>
            <h4 className='marginTop'>ZIP</h4>
            <div className="form-group">
              <input
                id="TXTzipcode"
                type="text"
                name="representative_zipcode"
                value={formData.representative_zipcode}
                onChange={handleInputChange}
              />
    
            </div>
          </div>
          <div className="form-row">
            <h4 className='marginTop'>Email</h4>
            <div className="form-group">  
              <input
                 id="TXTemail"
                 type="text"
                 name="representative_email"
                 value={formData.representative_email}
                 onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <h4 className='marginTop'>Home phone</h4>
            <div className="form-group"> 
              <input
                 id="TXThome"
                 type="text"
                 name="representative_homePhone"
                 value={formData.representative_homePhone}
                 onChange={handleInputChange}
              />
    
            </div>
            <h4>Cell</h4>
            <div className="form-group">
              
              <input
                   id="TXTcell"
                   type="text"
                   name="representative_cellPhone"
                   value={formData.representative_cellPhone}
                   onChange={handleInputChange}
              />
    
            </div>
          </div>
          <div className="form-row">
            <h4 className='marginTop'>Work</h4>
            <div className="form-group">
              
              <input
                 id="TXTwork"
                 type="text"
                 name="representative_workPhone"
                 value={formData.representative_workPhone}
                 onChange={handleInputChange}
              />
    
            </div>
            <h4 className='marginTop'>Work ext.</h4>
            <div className="form-group">
              
              <input
                   id="TXText"
                   type="text"
                   name="representative_workPhoneext"
                   value={formData.representative_workPhoneext}
                   onChange={handleInputChange}
              />
    
            </div>
          </div>
          <h3>Authorization to treat</h3>
          <div >
            <p>I (and/or the undersigned on behalf of the patient) voluntarily consent to allow Optum physicians and 
            staff to provide such evaluation and/or care and treatments as an outpatient on a continuing basis and as 
            an inpatient as necessary, as Optum physicians and staff may decide is advisable and necessary. </p>
            <p>I understand that although care is reviewed and supervised by Optum physicians, actual care may be 
            rendered by physician extenders (i.e., physician assistants, nurse practitioners, certified nurse midwife). 
            I further understand that residents, medical students, physician assistant students, nurse practitioner 
            students, nursing students, pharmacy students or other allied health professional students may assist in 
            my treatment. </p>
            <p>I am advised that such treatment may include physical examination, X-ray examination, laboratory 
            procedures, other office procedures as well as inpatient procedures as required.</p>
            <p>I understand that should I execute a Durable Power of Attorney for Health Care or other Advance 
            Directive, I will provide an executed copy to my physician. I further understand that I will notify my 
            physician of any changes in the Directive. </p>
            <p>I understand that I will be informed about the course of my treatment. Also, I am free to terminate my 
            treatment with my Optum physician at anytime. </p>
          </div>
          <h3>Assignment of benefits </h3>
          <div >
            <p>I hereby assign medical and/or surgical benefits, private insurance, and any other health plan benefits to 
            Optum. A copy of this assignment is considered valid as the original.</p>
            <div className="form-row">
            <div className="form-group">
              
              <input
                 id="TXTrname"
                 type="text"
                 name="PrintName"
                 value={formData.PrintName}
                 onChange={handleInputChange}
              /><label htmlFor="TXTPrintName" className="label">Form completed by (print) </label>
    
            </div>
            <div className="form-group">
              <input
                   id="TXTApt"
                   type="text"
                   name="filled_date"
                   value={formData.filled_date}
                   onChange={handleInputChange}
              /><label htmlFor="TXTfilled_date" className="label">Date</label>
    
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              
              <input
                 id="TXTfull_name"
                 type="text"
                 name="full_name"
                 value={formData.full_name}
                 onChange={handleInputChange}
              /><label htmlFor="TXTPrintName" className="label">Signature</label>
    
            </div>
            <div className="form-group">
              
              <input
                   id="TXTrelationship"
                   type="text"
                   name="relationship"
                   value={formData.relationship}
                   onChange={handleInputChange}
              /><label htmlFor="TXTrelationship" className="label">Relationship to patient</label>
    
            </div>
          </div>
          </div>
          <div className="page-number">
            2
        </div>
        </form>
      </div>
      <div className='space'></div>
    </div>
  );
};
export default Pagetwo;