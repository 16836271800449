import React from "react";
import TaskStepper from "../Components/TaskStepper/TaskStepper";
import { EmptyTaskStep, TaskStepConfig } from "../Components/TaskStepper/taskDefs";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { tracker } from "../appTracker";
import { AppThemeColorKeys } from "../theme";
import { gotoTaskRoute, setPendingStartRoute } from "../features/task/taskSlice";
import { 
  updatePrefIdentityVerified, 
  updatePrefIsKioskMode, 
  updatePrefIsPublicDevice, 
  updatePrefIsWebAccessCodeMode,
} from "../features/preferences/prefSlice";
import TermsAndConditionsPage from "./TermsAndConditionsPage";
import MultiAppointmentsPage from "./MultiAppointmentsPage";
import MultiAppointmentsFinishScreen from "./MultiAppointmentsFinishScreen";
import MultiAppointmentsCancelScreen from "./MultiAppointmentsCancelScreen";
import VerificationScreen from "./forms/VerificationScreens";
import ReviewPatientNameScreen from "./forms/reviewFormScreens/ReviewPatientNameScreen";
import PatientDemographicsScreen from "./forms/reviewFormScreens/DemographicsScreen";
import ReviewAddressScreen from "./forms/reviewFormScreens/ReviewAddressScreen";
import ReviewEmailScreen from "./forms/reviewFormScreens/ReviewEmailScreen";
import PhotoIDScreen from "./idCardCaptureScreens/PhotoIDScreen";
import RepresentativePhotoIDScreen from "./idCardCaptureScreens/RepresentativePhotoIDScreen";
import ReviewScreen from "./ReviewScreen";
import ConsentFormScreen from "./ConsentFormScreen";
import InsuranceCardScreen from "./insuranceScreens/InsuranceCardScreen";
import SecondaryInsuranceCardScreen from "./insuranceScreens/SecondaryInsuranceCardScreen";
import ReviewInsuranceScreen from "./insuranceScreens/ReviewInsuranceScreen";
import ReviewInsuranceCopayBalance from "./insuranceScreens/ReviewInsuranceCopayBalance";
import PreRegisteredFinishScreen from "./PreRegisteredFinishScreen";
import KioskRegisteredFinishScreen from "./KioskRegisteredFinishScreen";
import KioskArrivedFinishScreen from "./KioskArrivedFinishScreen";
import SelfIdentificationScreen from "./SelfIdentificationScreen";
import PageNotFoundErrorPage from "./errors/PageNotFoundErrorPage";
import LinkInvalidErrorPage from "./errors/LinkInvalidErrorPage";
import MaintenanceErrorPage from "./errors/MaintenanceErrorPage";
import NoAppointmentsErrorPage from "./errors/NoAppointmentsErrorPage";
import SessionExpiredErrorPage from "./errors/SessionExpiredErrorPage";
import VerificationErrorPage from "./errors/VerificationErrorPage";
import TechnicalDifficultiesErrorPage from "./errors/TechnicalDifficultiesErrorPage";
import KioskPatientScreen from "./kioskScreens/KioskPatientScreen";
import { getQueryStringParams } from "../utility/utilityFunctions";
import { isTaskRouteSameOrAfter, isTaskRouteSameOrBefore, TaskRouteInfo } from "../Components/TaskStepper/TaskStepperHelpers";
import { appInactivityTimer } from "../appInactivityTimer";
import {
  loadAndInitApplicationData,
  loadProtectedApplicationDataOnce,
  parsePatientIdFromParams,
  parseWebAccessCodeFromParams
} from "../appInit";
import Aem from "../lib/aem/components/Aem";
import { store } from "../app/store";
import config from "../config";



const LOG_ALL_APP_ROUTES = false && (process.env.NODE_ENV === "development" && config.deployEnv !== "production");

let _formLoaded: boolean = false;
let _formFinished: boolean = false;

interface AppRoutingTaskStepperProps {
  kioskMode?: boolean;
  webAccessCodeMode?: boolean;
  publicDevice?: boolean;
}

export const AppRoutingTaskStepper: React.FC<AppRoutingTaskStepperProps> = (props: AppRoutingTaskStepperProps) => {
  const dispatch = useAppDispatch();

  const allRoutes: string[] = useAppSelector((state) => state.task.allRoutes);
  const refreshConfig: any = useAppSelector((state) => state.task.refreshConfig);
  const isReturningPatient: boolean = useAppSelector<boolean>((state) => state.appt.isReturningPatient);
  // const needsAdvancedDirectiveForm: boolean = useAppSelector<boolean>((state) => state.appt.needsAdvancedDirectiveForm);
  

  const kioskMode: boolean = !!props.kioskMode;
  const webAccessCodeMode: boolean = !!props.webAccessCodeMode;
  const publicDevice: boolean = !!props.publicDevice;
  const defaultStartRoute: string | undefined = kioskMode ? "kiosk" : "terms";
  
  dispatch(updatePrefIsKioskMode(kioskMode));
  dispatch(updatePrefIsWebAccessCodeMode(webAccessCodeMode));
  dispatch(updatePrefIsPublicDevice(publicDevice));
  const visitTypes = JSON.stringify(localStorage.getItem("categoryVisitType"));
  let hideConsentForm: boolean = false;
  if (visitTypes && visitTypes !== "null") {
     const jsonObject =eval('['+ visitTypes +']');
      let appttype = JSON.parse(decodeURIComponent(jsonObject))['categoryVisitType'];
      if (appttype === "inperson") {
        hideConsentForm = true;
      }
}


  let taskConfig: TaskStepConfig = {
    startRoute: defaultStartRoute,
    steps: [
      {
        // terms start page
        route: "terms",
        themeColor: AppThemeColorKeys.orange,
        component: TermsAndConditionsPage,
        showPrevButton: false,
        includeInSteps: false,        // terms page is not part of a step in the stepper display
        isHidden: false,
        isStart: true,
        isForm: false,
      },
      {
        route: "kiosk",
        themeColor: AppThemeColorKeys.orange,
        component: KioskPatientScreen,
        showPrevButton: false,
        includeInSteps: false,  
        isHidden: true,
        isStart: false,
        isForm: false,
      },      
      {
        // multi appointment flow
        route: "landing",
        themeColor: AppThemeColorKeys.orange,
        component: MultiAppointmentsPage,
        showPrevButton: true,
        isHidden: false,
        includeInSteps: false,        // this page is not part of a step in the stepper display
        isStart: false,
        isForm: false,
      },
      {
        // step #1 - verify identity
        route: "verify",
        themeColor: AppThemeColorKeys.orange,
        label: <Aem cid="BODY_STEPPER_TEXT_1">Verify who you are</Aem>,
        title: (
          <Aem cid="HEADER_STEPPER_TEXT_1">
            Let's start with verifying your identity.
          </Aem>
        ),
        subtitle: "",
        showPrevButton: false,
        includeInSteps: true,
        childSteps: [
          {
            route: "identify",
            component: SelfIdentificationScreen,
          },
          {
            route: "dob",
            component: VerificationScreen,
            componentProps: { verifyStep: 1 },
          },
          {
            route: "address",
            component: VerificationScreen,
            componentProps: { verifyStep: 2 },
          },
        ],
      },
      {
        // step #2 - review information
        route: "reviewPatient",
        themeColor: AppThemeColorKeys.green,
        label: <Aem cid="BODY_STEPPER_TEXT_2">Review your information</Aem>,
        title: <Aem cid="BODY_STEPPER_TEXT_4">Great, thank you!</Aem>,
        subtitle: (
          <Aem cid="BODY_STEPPER_TEXT_5">
            Next let's review patient information.
          </Aem>
        ),
        showPrevButton: true,
        includeInSteps: !isReturningPatient,    // NOTE: special case, skip the 2nd step if this is a returning patient.  refreshConfig is used to update config
        childSteps: [
          {
            route: "name",
            component: ReviewPatientNameScreen,
          },
          {
            route: "demographics",
            component: PatientDemographicsScreen,
          },
          {
            route: "address",
            component: ReviewAddressScreen,
          },
          {
            route: "email",
            component: ReviewEmailScreen,
          },
          {
            route: "patientPhotoID",
            component: PhotoIDScreen,
          },
          {
            route: "representativePhotoID",
            component: RepresentativePhotoIDScreen,
          },
          {
            route: "insurance",
            component: ReviewInsuranceScreen,
          },
          {
            route: "cardImage",
            component: InsuranceCardScreen,
            componentProps: { coverageNum: 1 },
          },
          {
            route: "cardImage2",
            component: SecondaryInsuranceCardScreen,
            componentProps: { coverageNum: 2 },
          },
          {
            route: "reviewInsuranceCopayBalance",
            component: ReviewInsuranceCopayBalance,
          },
        ],
      },
      // {
      //   // step #3 - review medical history
      //   route: "reviewMedical",
      //   themeColor: AppThemeColorKeys.purple,
      //   label: "Review your medical history",
      //   title: "Next we'll review medical history.",
      //   subtitle: "",
      //   showPrevButton: false,
      //   childSteps: [
      //     {
      //       route: "conditions",
      //       component: ConditionsScreen,
      //     },
      //     {
      //       route: "medications",
      //       component: MedicationsScreen,
      //     },
      //     {
      //       route: "allergies",
      //       component: AllergiesScreen,
      //     },
      //     {
      //       route: "immunizations",
      //       component: ImmunizationsScreen,
      //     },
      //   ],
      // },
      {
        // step #3 - consent forms
        route: "consent",
        themeColor: AppThemeColorKeys.blue,
        label: hideConsentForm?<Aem cid="BODY_STEPPER_TEXT_8">Summary</Aem>:<Aem cid="BODY_STEPPER_TEXT_3">Sign consent forms</Aem>,
        title: <Aem cid="BODY_STEPPER_TEXT_6">Almost done!</Aem>,
        subtitle: (
          <Aem cid="BODY_STEPPER_TEXT_7">
            Lastly, you'll sign our consent forms.
          </Aem>
        ),
        showPrevButton: true,
        includeInSteps: true,
        childSteps: [
          // {
          //   route: "advancedDirective",
          //   component: AdvancedDirectiveScreen,
          //   isHidden: hideConsentForm ? hideConsentForm:(!needsAdvancedDirectiveForm || !isAdultPatient) // only shown if form needed and patient is an adult
          // },
          {
            route: "consentForm",
            component: ConsentFormScreen,
            isHidden: hideConsentForm,
          },
          {
            route: "review",
            component: ReviewScreen,
          },
        ],
      },
      {
        // finish page
        route: "finish",
        themeColor: AppThemeColorKeys.blue,
        component: MultiAppointmentsFinishScreen,
        includeInSteps: false, // this page is not part of a step in the stepper display
        isHidden: kioskMode,
        isFinal: true,
        isForm: false,
      },
      {
        // all appts cancelled page
        route: "allApptsCancel",
        themeColor: AppThemeColorKeys.blue,
        component: MultiAppointmentsCancelScreen,
        includeInSteps: false, // this page is not part of a step in the stepper display
        isHidden: true,
        isFinal: true,
        isForm: false,
      },
      {
        // alternate finish pages
        route: "preRegisteredFinish",
        themeColor: AppThemeColorKeys.blue,
        component: PreRegisteredFinishScreen,
        includeInSteps: false, // this page is not part of a step in the stepper display
        isHidden: true,
        isFinal: true,
        isForm: false,
      },
      {
        route: "kioskFinish",
        themeColor: AppThemeColorKeys.blue,
        component: KioskRegisteredFinishScreen,
        includeInSteps: false, // this page is not part of a step in the stepper display
        isHidden: !kioskMode,
        isFinal: true,
        isForm: false,
      },
      {
        route: "kioskArrivedFinish",
        themeColor: AppThemeColorKeys.blue,
        component: KioskArrivedFinishScreen,
        includeInSteps: false, // this page is not part of a step in the stepper display
        isHidden: true,
        isFinal: true,
        isForm: false,
      },
      
      // error routes
      {
        route: "pageNotFoundError",
        themeColor: AppThemeColorKeys.maroon,
        component: PageNotFoundErrorPage,
        includeInSteps: false,
        isFinal: true,
        isError: true,
        isForm: false,
      },
      {
        route: "linkInvalidError",
        themeColor: AppThemeColorKeys.maroon,
        component: LinkInvalidErrorPage,
        includeInSteps: false,
        isFinal: true,
        isError: true,
        isForm: false,
      },
      {
        route: "sessionExpiredError",
        themeColor: AppThemeColorKeys.maroon,
        component: SessionExpiredErrorPage,
        includeInSteps: false,
        isFinal: true,
        isError: true,
        isForm: false,
      },
      {
        route: "verificationError",
        themeColor: AppThemeColorKeys.maroon,
        component: VerificationErrorPage,
        includeInSteps: false,
        isFinal: true,
        isError: true,
        isForm: false,
      },
      {
        route: "noAppointmentsError",
        themeColor: AppThemeColorKeys.maroon,
        component: NoAppointmentsErrorPage,
        includeInSteps: false,
        isFinal: true,
        isError: true,
        isForm: false,
      },
      {
        route: "maintError",
        themeColor: AppThemeColorKeys.maroon,
        component: MaintenanceErrorPage,
        includeInSteps: false,
        isFinal: true,
        isError: true,
        isForm: false,
      },
      {
        route: "techDifficultiesError",
        themeColor: AppThemeColorKeys.maroon,
        component: TechnicalDifficultiesErrorPage,
        includeInSteps: false,
        isFinal: true,
        isError: true,
        isForm: false,
      },
    ],
  };

  const firstProtectedRoute: string = "reviewPatient";
  const lastProtectedRoute: string = "finish";

  const isProtectedRoute = (newRoute: string): boolean => {
    let retval: boolean = false;
    let hasRoutes: boolean = (allRoutes?.length > 0);
    if (newRoute && hasRoutes &&
        isTaskRouteSameOrAfter(allRoutes, newRoute, firstProtectedRoute) && 
        isTaskRouteSameOrBefore(allRoutes, newRoute, lastProtectedRoute)) {
      retval = true;
    }
    return retval;
  };

  // NOTE: debug mode feature.  grab the startRoute from the URL params during init.
  if (config.debugMode && (!taskConfig.startRoute || taskConfig.startRoute === defaultStartRoute)) {
    let queryParams: any = getQueryStringParams();
    if (queryParams.startRoute) {
      let startRoute: string = queryParams.startRoute;
      taskConfig.startRoute = startRoute;
      if (isProtectedRoute(startRoute)) {
        dispatch(updatePrefIdentityVerified(true));
      }
      dispatch(setPendingStartRoute(startRoute));
    }
  }
  
  // application specific init logic should be handled here, not in the individual screens
  const initAppState = async (params: any, _ignoredTtaskRouteInfo: TaskRouteInfo): Promise<any> => {
    let patientId: string = parsePatientIdFromParams(params, true) || "";
    let webAccessCode: string = parseWebAccessCodeFromParams(params, true) || "";
    await loadAndInitApplicationData(patientId, webAccessCode, kioskMode, webAccessCodeMode, publicDevice, dispatch);

    if (LOG_ALL_APP_ROUTES) {
      let taskState: any = store.getState()?.task || {};
      let allRoutes: string[] = taskState?.allRoutes || [];
      let includedRoutes: string[] = taskState?.allRoutes.filter(v => taskState?.routeMap[v]?.included);
      let visibleRoutes: string[] = taskState?.visibleRoutes || [];
      let nonVisibleRoutes: string[] = allRoutes.filter(v => !visibleRoutes.includes(v));
      console.log("all app task routes: ");
      console.log(allRoutes.join("\n"));
      console.log("included app task routes: ");
      console.log(includedRoutes.join("\n"));      
      console.log("visible app task routes: ");
      console.log(visibleRoutes.join("\n"));
      console.log("non-visible app task routes: ");
      console.log(nonVisibleRoutes.join("\n"));
      console.log("\n");
    }
    return true;
  };

  const handleBeforeRouteChange = async (newRoute: string, oldRoute: string) => {
    let isProtected: boolean = isProtectedRoute(newRoute);
    if (isProtected) {
      // handle protected route logic here
      await loadProtectedApplicationDataOnce(dispatch);
    }
    return true;
  };

  const handleRouteChange = async (newRoute: string, oldRoute: string, newRouteInfo: any, oldRouteInfo: any) => {
    // console.log("ARTS.handleRouteChange: ", { newRoute, oldRoute, newRouteInfo, oldRouteInfo });

    if (newRoute) {
      // make sure we have a valid routeInfo
      newRouteInfo = newRouteInfo || EmptyTaskStep;
      
      let { isStart, isFinal, isError, isForm } = newRouteInfo;
      let isFormStep: boolean = (isForm === true || isForm === false)? isForm: (!isStart && !isFinal && !isError);

      // update the app inactivity timer
      if (isFinal || isError) { 
        appInactivityTimer.stop();
      } else {
        appInactivityTimer.reset();
      }

      // page track needs to come before form load or form step
      // it should come after form start
      tracker.page(newRoute);

      if ((isStart || isFormStep) && !_formLoaded) {
        _formLoaded = true;
        tracker.formLoaded(newRoute);
      }  

      // NOTE: form started has been moved to the entry page (terms)

      if (isFormStep) { 
        tracker.formStep(newRoute);
      }

      if (isFinal && _formLoaded && !_formFinished) { 
        _formFinished = true;
        tracker.formCompleted(newRoute);
      }
    }
  };

  const handleRouteNotFound = async (newRoute: string, oldRoute: string) => {
    if (newRoute) { 
      setTimeout(() => {
        dispatch(gotoTaskRoute("pageNotFoundError"));
      });
    }
  };

  return (
    <TaskStepper
      config={taskConfig}
      refreshConfig={refreshConfig}
      onInitParams={initAppState}
      onBeforeRouteChange={handleBeforeRouteChange}
      onRouteChange={handleRouteChange}
      onRouteNotFound={handleRouteNotFound}
    />
  );
};

AppRoutingTaskStepper.defaultProps = {
  kioskMode: false,
  webAccessCodeMode: false,
  publicDevice: false,
};

export default AppRoutingTaskStepper;


export const AppRoutingTaskStepperPublicKioskMode: React.FC<AppRoutingTaskStepperProps> = (props: AppRoutingTaskStepperProps) => {
  return <AppRoutingTaskStepper {...props} kioskMode={true} webAccessCodeMode={false} publicDevice={true} />;
};

export const AppRoutingTaskStepperPrivateKioskMode: React.FC<AppRoutingTaskStepperProps> = (props: AppRoutingTaskStepperProps) => {
  return <AppRoutingTaskStepper {...props} kioskMode={true} webAccessCodeMode={false} publicDevice={false} />;
};

export const AppRoutingTaskStepperPrivateWebAccessCodeMode: React.FC<AppRoutingTaskStepperProps> = (props: AppRoutingTaskStepperProps) => {
  return <AppRoutingTaskStepper {...props} kioskMode={false} webAccessCodeMode={true} publicDevice={false} />;
};

