import { AppointmentTypeIds } from "./Fhir";
import { LanguageIsoCodes } from "./languageData";
 
 
const PUBLIC_URL = process?.env?.PUBLIC_URL || "";
export const TermsOfUseUrl: string = `${PUBLIC_URL}/docs/terms_of_use_v1.pdf`;
export const PrivacyUrl: string = `${PUBLIC_URL}/docs/privacy_v1.pdf`;
export const TermsOfUseVersion: string = "1";
export const PrivacyVersion: string = "1";
 
export enum PciDocTypes {
  NONE                  = "",                       // Invalid doc type
  PATIENT_REGISTRATION  = "PATIENT_REGISTRATION",   // Patient registration
  AUTHORIZATION         = "AUTHORIZATION"           // Authorization for use and disclosure form
}
 
// all forms are stored under this appointmentTypeId
export const DocAppointmentTypeId: string = AppointmentTypeIds.NewPatient;
 
// NOTE: all 3 form types are now included for all appointment types and the default
// map of expected forms based on PCI appointmentTypeId
const AppointmentTypeIdToPciDocTypesMap = {
  [AppointmentTypeIds.NewPatient]:          [ PciDocTypes.PATIENT_REGISTRATION, PciDocTypes.AUTHORIZATION ],
  [AppointmentTypeIds.EstablishedPatient]:  [ PciDocTypes.PATIENT_REGISTRATION, PciDocTypes.AUTHORIZATION ],
  [AppointmentTypeIds.PhysicalExam]:        [ PciDocTypes.PATIENT_REGISTRATION, PciDocTypes.AUTHORIZATION ],
};
const DefaultDocTypes =                     [ PciDocTypes.PATIENT_REGISTRATION, PciDocTypes.AUTHORIZATION ];
 
// map of form template key based on PciDocType  
// NOTE: this are Cali CDO specific
const PciDocTypeToFormKeyMap = {
  [LanguageIsoCodes.eng]: {
    [PciDocTypes.PATIENT_REGISTRATION]: "caliPatRegForm_eng",
    [PciDocTypes.AUTHORIZATION]: "caliAuthorizationForm_eng",
 
  },
  [LanguageIsoCodes.spa]: {
    [PciDocTypes.PATIENT_REGISTRATION]: "caliPatRegForm_spa",
    [PciDocTypes.AUTHORIZATION]: "caliAuthorizationForm_spa",
  },
};
 

 const makePciDocTypeLookupMap = (): any => {
    let map: any = {};
    Object.values<any>(PciDocTypeToFormKeyMap).forEach((langMap: any) => {
      Object.entries<any>(langMap).forEach(([pciDocType, formKey]) => {
        if (pciDocType && formKey) {
          map[formKey] = pciDocType;
        }
      });
    });
    return map;
 };
 
const templateKeyToDocTypeMap: any = makePciDocTypeLookupMap();
 
export const GetFormTemplateKeyFromPciDocType = (
  docType: PciDocTypes,
  languageIsoCode: string,
): string => {
  let tmpMap: any = PciDocTypeToFormKeyMap[languageIsoCode] || {};
  let formKey: string = tmpMap[docType] || "";
  return formKey;
};
 
export const GetDocTypeFromTemplateKey = (templateKey: string): PciDocTypes => {
  let docType: PciDocTypes = PciDocTypes.NONE;
  let tmp: any = templateKey? templateKeyToDocTypeMap[templateKey]: undefined;
  if (tmp !== undefined) {
    docType = tmp as PciDocTypes;
  }
  return docType;
};
 
export interface DocCounts {
  [PciDocTypes.PATIENT_REGISTRATION]: number;
  [PciDocTypes.AUTHORIZATION]: number;
}
 
const emptyDocCounts: DocCounts = {
  [PciDocTypes.PATIENT_REGISTRATION]: 0,
  [PciDocTypes.AUTHORIZATION]: 0,
};
 
export const CreateNewDocCounts = (initDocCounts: any = undefined): DocCounts => {
  return {
    ...emptyDocCounts,
    ...initDocCounts,
  } as DocCounts;  
};
 
export const HasAllExpectedFormsForAppointmentTypeId = (docCounts: DocCounts | undefined, apptTypeId: string, filterDocTypes: PciDocTypes[] | undefined = undefined): boolean => {
  let docTypes: PciDocTypes[] = AppointmentTypeIdToPciDocTypesMap[apptTypeId] || DefaultDocTypes;
 
  if (filterDocTypes !== undefined) {
    docTypes = docTypes?.filter(v => filterDocTypes.includes(v));
  }
 
  return HasAllExpectedFormsForAllPciDocTypes(docCounts, docTypes);
};
 
export const HasAllExpectedFormsForAllPciDocTypes = (docCounts: DocCounts | undefined, docTypes: PciDocTypes[]): boolean => {
  let retval: boolean = true;
  if (docTypes?.length > 0) {
    // this is a negative search, if we find a match, then we don't have a valid doc count and we can bail
    docTypes.find(docType => {
      let count = docCounts? docCounts[docType]: 0;
      if (count === undefined || count <= 0) {
        retval = false;
        return true;
      }
      return false;
    });
  }
  return retval;
};
 
export const GetFormTemplateKeysFromAppointmentTypeId = (
  apptTypeId: string,
  languageIsoCode: string,
  advancedDirectiveEnabled: boolean = true,
  advancedDirectiveSelection: string = ""
): string[] => {
  return GetFormTemplateKeysFromDocCounts(null, apptTypeId, languageIsoCode, advancedDirectiveEnabled, advancedDirectiveSelection);
};
 
export const GetFormTemplateKeysFromDocCounts = (
  docCounts: DocCounts | undefined | null,
  apptTypeId: string,
  languageIsoCode: string,
  advancedDirectiveEnabled: boolean = true,
  advancedDirectiveSelection: string = ""
): string[] => {
  // NOTE: only use DefaultDocTypes if undefined.  if we get empty list [] back, then assume we want an empty list.
  let docTypes: PciDocTypes[] = AppointmentTypeIdToPciDocTypesMap[apptTypeId] || DefaultDocTypes;
 
  
 
  let templateKeys: string[] = [];
  if (docTypes?.length > 0) {
    docTypes.forEach(docType => {
      let count = docCounts? docCounts[docType]: 0;
      if (count === undefined || count <= 0) {
        let formKey: string = GetFormTemplateKeyFromPciDocType(docType, languageIsoCode);
        if (formKey) {
          templateKeys.push(formKey)
        }
      }
    });
  }
  return templateKeys;
};
 